@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss ignore */
#nprogress .spinner {
  display: none !important;
}

body,
html {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  font-size: 16px;
}

quill-lt-popup {
  z-index: 9999;
}
